var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("body", { staticClass: "bg-primary" }, [
      _c("div", [
        _c(
          "div",
          {
            staticClass:
              "container d-flex align-items-center ui-mh-100vh text-white",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "row align-content-center align-items-start w-100 py-5",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-6 order-2 order-md-1 text-md-left text-center",
                  },
                  [
                    _c(
                      "h1",
                      { staticClass: "display-2 font-weight-bolder mb-4" },
                      [_vm._v("301")]
                    ),
                    _c(
                      "div",
                      { staticClass: "text-xlarge font-weight-light mb-5" },
                      [
                        _vm._v(" Você não está autenticado, "),
                        _c("br"),
                        _vm._v(" por favor faça login na aplicação. "),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-6 order-1 order-md-2 text-center mb-5 mb-md-0",
                    attrs: { id: "pcVobys" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ui-device macbook w-100 rounded",
                        staticStyle: { "max-width": "500px" },
                      },
                      [
                        _c("img", {
                          staticClass: "device-img",
                          attrs: {
                            src: require("@/assets/macbook-gold.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "device-content" }, [
                          _c(
                            "div",
                            { staticClass: "error-device-content bg-primary" },
                            [
                              _c("img", {
                                staticClass: "device-img",
                                attrs: {
                                  src: require("@/assets/logoVobys.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }