<template>
  <body class="bg-primary">
    <div>
      <div class="container d-flex align-items-center ui-mh-100vh text-white">
        <div class="row align-content-center align-items-start w-100 py-5">
          <div class="col-md-6 order-2 order-md-1 text-md-left text-center">
            <h1 class="display-2 font-weight-bolder mb-4">301</h1>
            <div class="text-xlarge font-weight-light mb-5">
              Você não está autenticado,
              <br />
              por favor faça login na aplicação.
            </div>
          </div>

          <div id="pcVobys" class="col-md-6 order-1 order-md-2 text-center mb-5 mb-md-0">
            <div class="ui-device macbook w-100 rounded" style="max-width: 500px">
              <img class="device-img" src="@/assets/macbook-gold.png" alt="" />
              <div class="device-content">
                <div class="error-device-content bg-primary">
                  <img class="device-img" src="@/assets/logoVobys.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
import backgroundImage from '@/assets/forbidden.jpg';
export default {
  data() {
    return { backgroundImage };
  },
  name: 'Forbidden',
  beforeMount: function () {},
  methods: {
    shows() {},
  },
};
</script>
<style>
.font-weight-bolder {
  font-weight: 900 !important;
}
.bg-primary {
  background-color: #26b4ff !important;
}

.text-xlarge {
  font-size: 220% !important;
}

html:not([dir='rtl']) .rounded {
  border-radius: 0.25rem !important;
}

html:not([dir='rtl']) .pb-5,
html:not([dir='rtl']) .py-5 {
  padding-bottom: 3rem !important;
}

html:not([dir='rtl']) .pt-5,
html:not([dir='rtl']) .py-5 {
  padding-top: 3rem !important;
}

.ui-mh-100vh {
  min-height: 100vh;
}

.container {
  padding-right: 2rem;
  padding-left: 2rem;
  max-width: 1140px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.error-device-content {
  position: absolute;
  text-align: center;
  line-height: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-white {
  color: #fff !important;
}
.text-center {
  text-align: center !important;
}

img {
  vertical-align: middle;
  border-style: none;
  overflow-clip-margin: content-box;
  overflow: clip;
}
.ui-device.macbook .device-content {
  margin-top: 4.31947840260799%;
  margin-left: 12.46943765281174%;
  width: 75.15262428687857%;
}
.ui-device .device-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.ui-device.macbook .device-content::before {
  padding-top: 62.47288503253796%;
}
.ui-device {
  position: relative;
  user-select: none !important;
}

.ui-device .device-content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.ui-device .device-content {
  position: absolute;
  top: 0;
  z-index: 2;
}
.ui-device .device-content::before {
  content: '';
  display: block;
  padding-top: 100%;
}

div {
  display: block;
}
body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.894rem;
  font-weight: 400;
  line-height: 1.47;
  color: #4e5155;
  text-align: left;
  background-color: #fff;
  background: #f8f8f8;
}
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
</style>
